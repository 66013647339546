<template>
  <div>
    <h2 class='text-xl font-semibold'>
      Affiliations
    </h2>
    <div class='text-gray-500 text-sm font-normal normal-case mb-4'>Drag-and-drop to re-order affiliations.</div>
    <table class='w-full text-xs'>
      <thead>
        <tr class='text-sm text-gray-600'>
          <th class='text-left px-2 py-2 w-10'>#</th>
          <th class='text-left px-2 py-2'>Affiliation</th>
          <th class='text-left px-2 py-2'>Department</th>
          <th class='text-left px-2 py-2'>Country</th>
        </tr>
      </thead>
      <draggable v-model='affiliationList' tag='tbody'>
        <tr v-for='affiliation in affiliationList'
          :key='`affiliation-${affiliation.affiliationIndex}`'
          class='border-t cursor-move hover:bg-gray-100'>
            <td class='text-left px-2 py-2'>{{ affiliation.affiliationIndex }}</td>
            <td class='text-left px-2 py-2'>{{affiliation.affiliation}}</td>
            <td class='text-left px-2 py-2'>{{affiliation.department}}</td>
            <td class='text-left px-2 py-2'>{{affiliation.country}}</td>
            <td class='text-right pl-2 py-2 flex flex-row justify-end items-center gap-x-1'>
              <button
                @click='editAffiliation(affiliation)'
                class='hover:bg-gray-200 p-1 rounded-md'>
                <edit-icon class='h-4 ' />
              </button>
              <button
                @click='removeAffiliation(affiliation)'
                class='hover:bg-gray-200 p-1 rounded-md'>
                <x-icon class='h-4'/>
              </button>
            </td>
          </tr>
      </draggable>
    </table>
    <div>
      <button
        @click='addAffiliation'
        class='border border-solid border-gray-200 hover:border-gray-500 py-2 px-16 text-center text-white text-sm uppercase block rounded-md hover:shadow-md'
        style='background-color:var(--eventMainColor);'>
        <plus-icon class='inline-block h-4' /> Add Affiliation
      </button>
    </div>
  </div>
</template>

<script>
import { XIcon, EditIcon, PlusIcon } from 'vue-tabler-icons'
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import draggable from 'vuedraggable'

export default {
  name: 'AbstractSubmissionAffiliations',
  components: {
    draggable,
    EditIcon,
    XIcon,
    PlusIcon,
  },
  computed: {
    ...mapFields('abstracts', [
      'editingAbstractSubmission'
    ]),
    affiliationList: {
      get () {
        return this.editingAbstractSubmission.affiliations
      },
      set (newList) {
        this.updateAffiliations(newList)
      }
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'resetEditingAffiliation',
    ]),
    ...mapMutations('abstracts', [
      'updateAffiliations',
      'removeAffiliation',
      'setEditingAffiliation',
    ]),
    addAffiliation () {
      this.resetEditingAffiliation()
      this.$modal.show('edit-affiliation')
    },
    editAffiliation (affiliation) {
      this.setEditingAffiliation(affiliation)
      this.$modal.show('edit-affiliation')
    },
  },
}
</script>
